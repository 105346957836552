import dance from "./img/dance.gif"
import btc from "./img/btc.gif"
import logo from "./img/logo.gif"
// import buybtc from "./img/wdbtc7.png"
import fuckyou from "./img/wdbtc3.png"
import kissass from "./img/wdbtc4.png"
import telegram from "./img/telegram.png"
import twitter from "./img/twitter.gif"

function App() {
	return (
		<div className="app">
			<div className="lazydev">
				dev is lazy, view this shit on a bigger screen dummy.
			</div>
			<div className="toolbar">
				<img src={btc} className="btc" alt="btc" />
				<div className="logo">
					<img src={logo}  alt="logo" />
					<div className="title"><label>WING</label><label>DINGS</label></div>
					{/* <div>ABCDEFGHIJKLMONPQRSTUVWSYZ</div> */}
				</div>
				<img src={dance} className="dance" alt="dance" />
			</div>
			<div className="container">
				<div className="content">
					<div>fuck the meta</div>
					<div>fuck art</div>
					<div>and fuck you too</div>
					<div>buy our shit, its cool</div>
					<div>or stay poor</div>
				</div>
				{/* <img src={buybtc} /> */}
				<img src={fuckyou} />
				<img src={kissass} />
			</div>

			<div className="footer">
				<div className="click">
					<div className="clickme"><a href="https://twitter.com/intent/post?text=%23Ordinals%20szn%20fuckers.%0A%20Give%20me%20my%20fucking%20whitelist%20%40WD_BTC%20%3D%3E%20%5BOrdinals%20Wallet%20Address%5D%20%20%0Ahttps%3A%2F%2Fwww.wd-btc.com%2F%2F%20%23BTC" target="_blank">CLICK ME</a></div>
					<div className="instr">click this u dummy. ^</div> 
				</div>
				<div className="socials">
					<a target="_blank" rel="noreferrer" href="https://t.me/+hVGzhPFpcr41YTAx">
						<img src={telegram} />
					</a>

					<a target="_blank" rel="noreferrer" href="https://twitter.com/WD_BTC">
						<img src={twitter} />
					</a>
				</div>
			</div>
		</div>
	);
}

export default App;
